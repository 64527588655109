/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import { useEffect, useRef, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import LoadingWrapper from "../../../../components/LoadingWrapper";
import { Document, Page, pdfjs } from "react-pdf";
import { AppGlobals } from "../../../../App";
import {
  downloadMtbCustomOnboardingDocument,
  submitDocument,
} from "../../../../services/EmployeeOnboardingService";
import {
  base64PdfToBase64Images,
  base64ToFile,
  reactPdfToBase64,
} from "../../../../tools";
import moment from "moment";
import DocumentWizard from "../../DocumentWizard";
import CustomDocument from "./CustomDocument";
import { CustomDocumentPdf } from "./CustomDocumentPdf";
import { PDFViewer } from "@react-pdf/renderer";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export default function CustomDocumentForm({
  document,
  signature,
  updateDocumentToCompleted,
  hideModal,
  onboardingToken,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [signatureBoxes, setSignatureBoxes] = useState([]);
  const [dateBoxes, setDateBoxes] = useState([]);
  const [file, setFile] = useState(undefined);
  const [zoom, setZoom] = useState(1);
  const [numPages, setNumPages] = useState();
  const [pngImages, setPngImages] = useState([]);
  const [customDocument, _] = useState(new CustomDocument(signatureBoxes));
  customDocument.setValidation("signedBoxes", (_) => {
    if (!signatureBoxes.every((sigBox) => sigBox.signed)) {
      return "Required";
    }
  });

  const fieldRefs = [useRef(), useRef(), useRef(), useRef(), useRef()];

  useEffect(() => {
    loadData();
  }, []);

  async function loadData() {
    setIsLoading(true);
    const response = await downloadMtbCustomOnboardingDocument(
      document.documentInfo.uid
    );
    if (response.status === 200) {
      setFile(base64ToFile(response.base64));
      setPngImages(await base64PdfToBase64Images(response.base64, 1173));
      setSignatureBoxes(
        document.documentInfo.elements.filter((element) => element.type === 1)
      );
      setDateBoxes(
        document.documentInfo.elements.filter((element) => element.type === 2)
      );
    } else {
      AppGlobals.alert("danger", response.message);
    }
    setIsLoading(false);
  }

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  function signSignatureBox(index) {
    setSignatureBoxes((prevState) => {
      const newSignatureBoxes = Array.from(prevState);
      newSignatureBoxes[index]["signed"] = true;
      return newSignatureBoxes;
    });
  }

  const handleSubmit = async () => {
    const pdf = (
      <CustomDocumentPdf
        pngImages={pngImages}
        signature={signature}
        signatureBoxes={signatureBoxes}
        dateBoxes={dateBoxes}
        numPages={numPages}
      />
    );
    const base64 = await reactPdfToBase64(pdf);
    const response = await submitDocument(
      customDocument.encode(document, base64),
      onboardingToken
    );
    if (response.status === 200) {
      updateDocumentToCompleted("custom", base64, document.uid);
      hideModal();
      AppGlobals.alert("success", response.message);
    } else {
      AppGlobals.alert("danger", response.message);
    }
  };

  // return (
  //   <PDFViewer style={{height: '100%', width: '100%'}}>
  //     <CustomDocumentPdf
  //       pngImages={pngImages}
  //       signature={signature}
  //       signatureBoxes={signatureBoxes}
  //       dateBoxes={dateBoxes}
  //       numPages={numPages}
  //     />
  //   </PDFViewer>
  // )

  return (
    <>
      <Modal.Header closeButton>
        <Modal.Title>{document.documentInfo.name}</Modal.Title>
      </Modal.Header>
      <Modal.Body
        style={{
          padding: 0,
          overflowX: "scroll",
          backgroundColor: "lightgray",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <LoadingWrapper isLoading={isLoading}>
          <div
            style={{
              transform: `scale(${zoom})`,
              justifyContent: "center",
              gap: 12,
              height: (1100 + 16) * zoom,
            }}
          >
            <div
              style={{
                position: "relative",
                marginLeft: "auto",
                marginRight: "auto",
              }}
            >
              <div
                style={{
                  flex: 1,
                  height: "100%",
                  display: "flex",
                  justifyContent: "center",
                  background: "lightgray",
                }}
              >
                <Document file={file} onLoadSuccess={onDocumentLoadSuccess}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      padding: 16,
                      gap: 16,
                      position: "relative",
                    }}
                  >
                    {Array.from(new Array(numPages), (_, index) => (
                      <div
                        key={`page_container_${index + 1}`}
                        style={{ position: "relative" }}
                      >
                        <Page pageNumber={index + 1} />
                      </div>
                    ))}
                    {signatureBoxes.map((signatureBox, index) => {
                      return (
                        <CustomDocumentSignatureButton
                          key={index}
                          innerRef={fieldRefs[index]}
                          signature={signature}
                          signatureBox={signatureBox}
                          onClick={() => signSignatureBox(index)}
                        />
                      );
                    })}
                    {dateBoxes.map((dateBox, index) => {
                      const style = {
                        width: Number(dateBox.width),
                        height: Number(dateBox.height),
                        left: Number(dateBox.x),
                        top: Number(dateBox.y),
                        position: "absolute",
                      };

                      const fontSize =
                        Math.min(style.width, style.height) * 0.4;
                      return (
                        <div key={index} style={style}>
                          <p style={{ fontSize: fontSize, textAlign: 'center', margin: 0 }}>
                            {moment().format("MM/DD/YYYY")}
                          </p>
                        </div>
                      );
                    })}
                  </div>
                </Document>
              </div>
            </div>
          </div>
        </LoadingWrapper>
      </Modal.Body>
      <DocumentWizard
        document={customDocument}
        fieldRefs={fieldRefs}
        refCount={signatureBoxes.length}
        zoom={zoom}
        setZoom={setZoom}
        onSubmit={handleSubmit}
      />
    </>
  );
}

function CustomDocumentSignatureButton({
  signatureBox,
  signature,
  onClick,
  innerRef,
}) {
  const style = {
    width: Number(signatureBox.width),
    height: Number(signatureBox.height),
    left: Number(signatureBox.x),
    top: Number(signatureBox.y),
  };

  return signatureBox.signed ? (
    <img
      ref={innerRef}
      style={{ position: "absolute", ...style }}
      src={signature.image}
    />
  ) : (
    <Button
      ref={innerRef}
      style={{
        position: "absolute",
        zIndex: 1000,
        whiteSpace: "nowrap",
        ...style,
        width: undefined,
        height: undefined,
      }}
      onClick={onClick}
      variant="outline-primary"
    >
      Click To Sign Document
    </Button>
  );
}
