/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, { useEffect, useState } from "react";
import { Button, Card, ListGroup, Modal } from "react-bootstrap";
import W4Form from "./Documents/W4/W4Form";
import SignatureForm from "./Signature/SignatureForm";
import { useParams } from "react-router-dom";
import { downloadEdCustomOnboardingDocument, downloadMtbCustomOnboardingDocument, getEmployeeOnboardingData } from "../../services/EmployeeOnboardingService";
import LoadingWrapper from "../../components/LoadingWrapper";
import IT4Form from "./Documents/Tax/IT4/IT4Form";
import VA4Form from "./Documents/Tax/VA4/VA4Form";
import WH4Form from "./Documents/Tax/WH4/WH4Form";
import L4Form from "./Documents/Tax/L4/L4Form";
import StateObject from "../../models/State/StateObject";
import OfferLetterForm from "./Documents/OfferLetter/OfferLetterForm";
import A4Form from "./Documents/Tax/A4/A4Form";
import { pdfjs } from "react-pdf";
import MIW4Form from "./Documents/Tax/MIW4/MIW4Form";
import IT2104Form from "./Documents/Tax/IT2104/IT2104Form";
import K4Form from "./Documents/Tax/K4/K4Form";
import REV419Form from "./Documents/Tax/REV419/REV419Form";
import DirectDepositForm from "./Documents/DirectDeposit/DirectDepositForm";
import F8850Form from "./Documents/F8850/F8850Form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faChevronRight,
  faDownload,
  faExclamationTriangle,
} from "@fortawesome/free-solid-svg-icons";
import CompanyDocumentListItem from "./CompanyDocumentListItem";
import CompanyDocumentModal from "./CompanyDocumentModal";
import Address from "../../models/Address";
import moment from "moment";
import { downloadBase64 } from "../../tools";
import CustomButton from "../../components/CustomButton";
import { faPenToSquare } from "@fortawesome/free-regular-svg-icons";
import I9Form from "./Documents/I9/I9Form";
import MDDForm from "./Documents/MDD/MDDForm";
import { OnboardingDocumentsReadOnly } from "../../enums";
import CustomDocumentForm from "./Documents/CustomDocument/CustomDocumentForm";
import { AppGlobals } from "../../App";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export default function EmployeeOnboarding({}) {
  const { onboardingToken } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(undefined);

  const [companyDocuments, setCompanyDocuments] = useState([]);
  const [selectedCompanyDocument, setSelectedCompanyDocument] =
    useState(undefined);
  const [selectedCustomDocument, setSelectedCustomDocument] =
    useState(undefined);
  const [documentRequest, setDocumentRequest] = useState({ documents: [] });

  const [modalSwitch, setModalSwitch] = useState("");

  const [userData, setUserData] = useState({});
  const [companyData, setCompanyData] = useState({});
  const [isEmployer, setIsEmployer] = useState(false);
  const [signature, setSignature] = useState(undefined);

  useEffect(() => {
    loadData();
  }, []);

  const loadData = async () => {
    setIsLoading(true);
    const response = await getEmployeeOnboardingData(onboardingToken);
    if (response.status === 200) {
      setDocumentRequest(response.documentRequest);
      setCompanyDocuments(response.companyDocuments ?? []);
      setIsEmployer(response.isEmployer);
      setCompanyData(response.companyData);
      const newUserData = structuredClone(response.userData);
      newUserData["address"] = new Address(
        newUserData.address.uid,
        newUserData.address.thoroughfare,
        newUserData.address.premise,
        newUserData.address.locality,
        newUserData.address.administrativeArea,
        newUserData.address.postalCode,
        newUserData.address.lat,
        newUserData.address.lng
      );
      newUserData["ssn"] = newUserData["ssn"].toString() ?? "";
      setUserData(new StateObject(newUserData));
      if (
        response.documentRequest.documents.length > 0 ||
        (response.documentRequest.includeEContacts &&
          !response.documentRequest.submittedEContacts)
      ) {
        setModalSwitch("signature");
      }
    } else {
      setError(response.message);
    }
    setIsLoading(false);
  };

  const handleAcknowledgeCompanyDocument = () => {
    let newArray;
    if (documentRequest.tcCompanyUserIdentifier) {
      newArray = Array.from(
        companyDocuments.filter((d) => d.uid != selectedCompanyDocument.uid)
      );
    } else if (documentRequest.lhCompanyUserIdentifier) {
      newArray = Array.from(
        companyDocuments.filter(
          (d) => d.fileIdentifier != selectedCompanyDocument.fileIdentifier
        )
      );
    }
    setCompanyDocuments(newArray);
    setModalSwitch("");
  };

  const hideModal = () => {
    setModalSwitch("");
  };

  function updateDocumentToCompleted(documentType, base64, uid) {
    const newDocumentRequest = structuredClone(documentRequest);
    const completedDocument =
      documentType === "custom"
        ? newDocumentRequest.documents.find(
            (doc) => doc.documentType === documentType && doc.uid === uid
          )
        : newDocumentRequest.documents.find(
            (doc) => doc.documentType === documentType
          );
    if (isEmployer) {
      completedDocument["employerSubmission"] = base64;
      completedDocument["employerCompleted"] = moment().format("YYYY-MM-DD");
    } else {
      completedDocument["employeeSubmission"] = base64;
      completedDocument["employeeCompleted"] = moment().format("YYYY-MM-DD");
    }
    setDocumentRequest(newDocumentRequest);
  }

  const getDocumentForm = (doc) => {
    switch (doc.documentType) {
      case "w4":
        return (
          <W4Form
            document={doc}
            userData={userData}
            companyData={companyData}
            startDate={documentRequest.startDate}
            signature={signature}
            onboardingToken={onboardingToken}
            updateDocumentToCompleted={updateDocumentToCompleted}
            hideModal={hideModal}
          />
        );
      case "i9":
        return (
          <I9Form
            document={doc}
            userData={userData}
            companyData={companyData}
            ssn={documentRequest?.ssnBase64}
            dl={documentRequest?.dlBase64}
            signature={signature}
            onboardingToken={onboardingToken}
            isEmployer={isEmployer}
            startDate={documentRequest.startDate}
            updateDocumentToCompleted={updateDocumentToCompleted}
            hideModal={hideModal}
          />
        );
      case "dd":
        return (
          <DirectDepositForm
            document={doc}
            userData={userData}
            companyData={companyData}
            signature={signature}
            onboardingToken={onboardingToken}
            updateDocumentToCompleted={updateDocumentToCompleted}
            hideModal={hideModal}
          />
        );
      case "f8850":
        return (
          <F8850Form
            document={doc}
            userData={userData}
            companyData={companyData}
            signature={signature}
            isEmployer={isEmployer}
            onboardingToken={onboardingToken}
            updateDocumentToCompleted={updateDocumentToCompleted}
            hideModal={hideModal}
          />
        );
      case "a4":
        return (
          <A4Form
            document={doc}
            userData={userData}
            companyData={companyData}
            signature={signature}
            isEmployer={isEmployer}
            onboardingToken={onboardingToken}
            hideModal={hideModal}
            updateDocumentToCompleted={updateDocumentToCompleted}
          />
        );
      case "wh4":
        return (
          <WH4Form
            document={doc}
            userData={userData}
            signature={signature}
            onboardingToken={onboardingToken}
            updateDocumentToCompleted={updateDocumentToCompleted}
            hideModal={hideModal}
          />
        );
      case "k4":
        return (
          <K4Form
            document={doc}
            userData={userData}
            signature={signature}
            onboardingToken={onboardingToken}
            updateDocumentToCompleted={updateDocumentToCompleted}
            hideModal={hideModal}
          />
        );
      case "l4":
        return (
          <L4Form
            document={doc}
            userData={userData}
            companyData={companyData}
            signature={signature}
            isEmployer={isEmployer}
            onboardingToken={onboardingToken}
            updateDocumentToCompleted={updateDocumentToCompleted}
            hideModal={hideModal}
          />
        );
      case "mdd":
        return (
          <MDDForm
            document={doc}
            userData={userData}
            companyData={companyData}
            signature={signature}
            onboardingToken={onboardingToken}
            updateDocumentToCompleted={updateDocumentToCompleted}
            hideModal={hideModal}
          />
        );
      case "miw4":
        return (
          <MIW4Form
            document={doc}
            userData={userData}
            companyData={companyData}
            signature={signature}
            isEmployer={isEmployer}
            onboardingToken={onboardingToken}
            updateDocumentToCompleted={updateDocumentToCompleted}
            hideModal={hideModal}
          />
        );
      case "it2104":
        return (
          <IT2104Form
            document={doc}
            userData={userData}
            companyData={companyData}
            signature={signature}
            isEmployer={isEmployer}
            onboardingToken={onboardingToken}
            updateDocumentToCompleted={updateDocumentToCompleted}
            hideModal={hideModal}
          />
        );
      case "it4":
        return (
          <IT4Form
            document={doc}
            userData={userData}
            signature={signature}
            onboardingToken={onboardingToken}
            updateDocumentToCompleted={updateDocumentToCompleted}
            hideModal={hideModal}
          />
        );
      case "rev419":
        return (
          <REV419Form
            document={doc}
            userData={userData}
            companyData={companyData}
            signature={signature}
            isEmployer={isEmployer}
            onboardingToken={onboardingToken}
            updateDocumentToCompleted={updateDocumentToCompleted}
            hideModal={hideModal}
          />
        );
      case "va4":
        return (
          <VA4Form
            document={doc}
            userData={userData}
            signature={signature}
            onboardingToken={onboardingToken}
            updateDocumentToCompleted={updateDocumentToCompleted}
            hideModal={hideModal}
          />
        );
      case "offerLetter":
        return (
          <OfferLetterForm
            document={doc}
            userData={userData}
            companyData={companyData}
            signature={signature}
            onboardingToken={onboardingToken}
            updateDocumentToCompleted={updateDocumentToCompleted}
            hideModal={hideModal}
          />
        );
    }
  };

  const documentsToComplete = documentRequest.documents.filter((doc) => {
    if (
      isEmployer &&
      "employerCompleted" in doc &&
      !doc.employerCompleted &&
      doc.employeeCompleted
    ) {
      return true;
    } else if (!isEmployer && !doc.employeeCompleted) {
      return true;
    } else {
      return false;
    }
  });
  const completedDocuments = documentRequest.documents.filter((doc) => {
    return (
      (isEmployer && doc.employerCompleted) ||
      (!("employerCompleted" in doc) && doc.employeeCompleted) ||
      (!isEmployer && doc.employeeCompleted)
    );
  });
  const awaitingEmployeeDocuments = documentRequest.documents.filter(
    (d) => !d.employeeCompleted && isEmployer
  );

  const listItems = documentsToComplete.map((doc) => {
    if (doc.documentType === "custom") {
      return (
        <ListGroup.Item
          key={`${doc.documentType}${doc.uid}`}
          style={{
            display: "flex",
            color: "var(--bs-primary)",
            justifyContent: "space-between",
            alignItems: "center",
          }}
          action
          onClick={() => {
            setSelectedCustomDocument(doc);
            setModalSwitch("customDocument");
          }}
        >
          <span>{doc.documentInfo.name}</span>
          <FontAwesomeIcon icon={faChevronRight} />
        </ListGroup.Item>
      );
    }
    return (
      <ListGroup.Item
        key={doc.documentType}
        style={{
          display: "flex",
          color: "var(--bs-primary)",
          justifyContent: "space-between",
          alignItems: "center",
        }}
        action
        onClick={() => {
          setModalSwitch(doc.documentType);
        }}
      >
        <span>{OnboardingDocumentsReadOnly[doc.documentType]}</span>
        <FontAwesomeIcon icon={faChevronRight} />
      </ListGroup.Item>
    );
  });

  const completedListItems = completedDocuments.map((doc) => {
    if (doc.documentType === "custom") {
      return (
        <ListGroup.Item
          key={doc.uid}
          style={{
            display: "flex",
            color: "var(--bs-primary)",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div style={{ marginRight: 8, opacity: 0.5 }}>
            <span>{doc.documentInfo.name}</span>
            <FontAwesomeIcon icon={faCheck} style={{ marginLeft: 8 }} />
          </div>
          <div style={{ display: "flex", gap: 12 }}>
            <CustomButton
              icon={faDownload}
              onClick={async () => {
                const response = await downloadEdCustomOnboardingDocument(doc.uid);
                if(response.status === 200){
                    downloadBase64(
                        response.base64,
                        `${userData?.firstName} ${userData?.lastName} - ${doc.documentInfo.name}`
                      );
                }else{
                    AppGlobals.alert("danger", response.message);
                }

              }}
            />
          </div>
        </ListGroup.Item>
      );
    }

    return (
      <ListGroup.Item
        key={doc.documentType}
        style={{
          display: "flex",
          color: "var(--bs-primary)",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div style={{ marginRight: 8, opacity: 0.5 }}>
          <span>{OnboardingDocumentsReadOnly[doc.documentType]}</span>
          <FontAwesomeIcon icon={faCheck} style={{ marginLeft: 8 }} />
        </div>
        <div style={{ display: "flex", gap: 12 }}>
          {isEmployer && doc["employerCompleted"] && (
            <CustomButton
              icon={faPenToSquare}
              onClick={() => {
                setModalSwitch(doc.documentType);
              }}
            />
          )}
          <CustomButton
            icon={faDownload}
            onClick={() => {
              downloadBase64(
                doc.employerCompleted
                  ? doc.employerSubmission
                  : doc.employeeSubmission,
                `${userData?.firstName} ${userData?.lastName} ${
                  OnboardingDocumentsReadOnly[doc.documentType]
                }`
              );
            }}
          />
        </div>
      </ListGroup.Item>
    );
  });

  const companyDocumentsItems = companyDocuments.map((doc) => {
    return (
      <CompanyDocumentListItem
        key={doc.fileIdentifier ?? doc.uid}
        document={doc}
        showModal={() => {
          setSelectedCompanyDocument(doc);
          setModalSwitch("companyDocument");
        }}
      />
    );
  });

  const awaitingEmployeeDocumentItems = awaitingEmployeeDocuments.map((doc) => {
    return (
      <ListGroup.Item
        key={doc.documentType}
        style={{
          display: "flex",
          color: "var(--bs-primary)",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <span>{OnboardingDocumentsReadOnly[doc.documentType]}</span>
        <span style={{ color: "var(--bs-danger)" }}>Awaiting Employee</span>
      </ListGroup.Item>
    );
  });

  const modals = documentRequest.documents
    .filter((doc) => doc.documentType !== "custom")
    .map((doc) => {
      return (
        <Modal
          key={doc.documentType}
          show={modalSwitch === doc.documentType}
          onHide={hideModal}
          fullscreen
        >
          {getDocumentForm(doc)}
        </Modal>
      );
    });

  return (
    <LoadingWrapper isLoading={isLoading}>
      {!error ? (
        <div
          style={{
            padding: 12,
            display: "flex",
            flexDirection: "column",
            overflowY: "auto",
            gap: 12,
            height: "100%",
          }}
        >
          {!signature && documentsToComplete.length > 0 ? (
            <Card style={{ flex: 1 }}>
              <Card.Body
                style={{ display: "flex", flexDirection: "column", gap: 12 }}
              >
                <Button
                  onClick={() => {
                    setModalSwitch("signature");
                  }}
                >
                  Create Signature
                </Button>
              </Card.Body>
            </Card>
          ) : (
            <Card style={{ flex: 1 }}>
              <Card.Header>
                <Card.Title>{`Onboarding Documents for ${userData?.firstName} ${userData?.lastName}`}</Card.Title>
              </Card.Header>
              <Card.Body
                style={{ display: "flex", flexDirection: "column", gap: 12 }}
              >
                {listItems.length > 0 && (
                  <>
                    <span
                      style={{ opacity: 0.5, fontWeight: "bold", fontSize: 14 }}
                    >
                      DOCUMENTS TO SIGN
                    </span>
                    <ListGroup style={{ marginBottom: 12 }}>
                      {listItems}
                    </ListGroup>
                  </>
                )}
                {completedListItems.length > 0 && (
                  <>
                    <span
                      style={{ opacity: 0.5, fontWeight: "bold", fontSize: 14 }}
                    >
                      COMPLETED DOCUMENTS
                    </span>
                    <ListGroup style={{ marginBottom: 12 }}>
                      {completedListItems}
                    </ListGroup>
                  </>
                )}
                {awaitingEmployeeDocumentItems.length > 0 && (
                  <>
                    <span
                      style={{ opacity: 0.5, fontWeight: "bold", fontSize: 14 }}
                    >
                      DOCUMENTS AWAITING EMPLOYEE
                    </span>
                    <ListGroup style={{ marginBottom: 12 }}>
                      {awaitingEmployeeDocumentItems}
                    </ListGroup>
                  </>
                )}
              </Card.Body>
            </Card>
          )}
          {companyDocuments.length > 0 && (
            <Card style={{ flex: 1 }}>
              <Card.Header>
                <Card.Title>Company Documents to Acknowledge</Card.Title>
              </Card.Header>
              <Card.Body>
                <ListGroup>{companyDocumentsItems}</ListGroup>
              </Card.Body>
            </Card>
          )}
          <Modal
            show={modalSwitch === "companyDocument"}
            onHide={hideModal}
            backdrop="static"
            size="xl"
            fullscreen="xl-down"
          >
            <CompanyDocumentModal
              onboardingToken={onboardingToken}
              document={selectedCompanyDocument}
              onAcknowledge={handleAcknowledgeCompanyDocument}
            />
          </Modal>
          <Modal
            show={modalSwitch === "customDocument"}
            onHide={hideModal}
            backdrop="static"
            fullscreen
          >
            <CustomDocumentForm
              onboardingToken={onboardingToken}
              document={selectedCustomDocument}
              signature={signature}
              updateDocumentToCompleted={updateDocumentToCompleted}
              hideModal={hideModal}
            />
          </Modal>
          <Modal
            size="xl"
            show={modalSwitch === "signature"}
            onHide={hideModal}
            backdrop="static"
          >
            <SignatureForm
              onboardingToken={onboardingToken}
              isEmployer={isEmployer}
              hideModal={hideModal}
              signature={signature}
              setSignature={setSignature}
              userData={userData}
              companyData={companyData}
              documentRequest={documentRequest}
            />
          </Modal>
          {modals}
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}
        >
          <Card>
            <Card.Header>
              <Card.Title style={{ textAlign: "center" }}>Oops!</Card.Title>
            </Card.Header>
            <Card.Body
              style={{ display: "flex", alignItems: "center", gap: 12 }}
            >
              <span style={{ margin: "auto", textAlign: "center" }}>
                <FontAwesomeIcon
                  size="1x"
                  icon={faExclamationTriangle}
                  style={{ color: "var(--bs-danger)", marginRight: 8 }}
                />
                {error}
              </span>
            </Card.Body>
          </Card>
        </div>
      )}
    </LoadingWrapper>
  );
}
