/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, { useEffect, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import ControlStateProps from "../../../../models/State/ControlStateProps";
import { Validation } from "../../../../validation";
import moment from "moment";
import SubscriberWrapper from "../../../../components/SubscriberWrapper";
import DocumentWizard from "../../DocumentWizard";
import DocumentSignatureButton from "../../DocumentSignatureButton";
import OfferLetterPdf from "./OfferLetterPdf";
import { base64ToFile, reactPdfToBase64 } from "../../../../tools";
import { submitDocument } from "../../../../services/EmployeeOnboardingService";
import { AppGlobals } from "../../../../App";
import OfferLetter from "./OfferLetter";
import { Document, Page } from "react-pdf";
import { OfferLetterFormBackground } from "./OfferLetterFormBackground";

export default function OfferLetterForm({
  document,
  userData,
  companyData,
  signature,
  hideModal,
  onboardingToken,
  updateDocumentToCompleted,
}) {
  const [zoom, setZoom] = useState(1);
  const [calculatedHeight, setCalculatedHeight] = useState(0);
  const containerRef = useRef(null);
  const [offerLetter, _] = useState(OfferLetter.initFromData());
  const [file, setFile] = useState();
  const [numPages, setNumPages] = useState(null);
  const fieldRefs = [useRef()];

  useEffect(() => {
    const generatePdf = async () => {
      const pdf = (
        <OfferLetterFormBackground
          document={document}
          userData={userData}
          companyData={companyData}
        />
      );
      const base64 = await reactPdfToBase64(pdf);
      setFile(base64ToFile(base64));
    };
    generatePdf();
  }, [document, userData, companyData]);

  useEffect(() => {
    if (containerRef.current) {
      const baseHeight = containerRef.current.scrollHeight;
      const newHeight = (baseHeight + 16) * zoom;
      setCalculatedHeight(newHeight);
    }
  }, [zoom]);

  const handleSubmit = async () => {
    const pdf = (
      <OfferLetterPdf
        signature={signature}
        document={document}
        userData={userData}
        companyData={companyData}
      />
    );
    const base64 = await reactPdfToBase64(pdf);
    const response = await submitDocument(
      offerLetter.encode(document, base64),
      onboardingToken
    );
    if (response.status === 200) {
      updateDocumentToCompleted("offerLetter", base64);
      hideModal();
      AppGlobals.alert("success", response.message);
    } else {
      AppGlobals.alert("danger", response.message);
    }
  };

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  return (
    <>
      <Modal.Header closeButton />
      <Modal.Body
        style={{ padding: 0, overflow: "auto", backgroundColor: "lightgray" }}
      >
        <div
          style={{
            transform: `scale(${zoom})`,
            display: "flex",
            flexDirection: "column",
            transformOrigin: "top center",
            justifyContent: "center",
            gap: 12,
            padding: 16,
            height: "auto",
            minHeight: calculatedHeight,
          }}
        >
          <div style={{ marginLeft: "auto", marginRight: "auto" }}>
            {!!file && (
              <Document file={file} onLoadSuccess={onDocumentLoadSuccess}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    padding: 16,
                    gap: 16,
                    position: "relative",
                  }}
                >
                  {Array.from(new Array(numPages), (_, index) => (
                    <div
                      key={`page_container_${index + 1}`}
                      style={{ position: "relative" }}
                    >
                      <Page pageNumber={index + 1} />
                    </div>
                  ))}
                  <DocumentSignatureButton
                    innerRef={fieldRefs[0]}
                    signature={signature}
                    stateProps={
                      new ControlStateProps(
                        "signed",
                        offerLetter,
                        Validation.true
                      )
                    }
                    style={{
                      position: "absolute",
                      left: 90,
                      bottom: 90,
                      width: 118,
                      height: 40,
                      zIndex: 1000,
                    }}
                  />
                  <SubscriberWrapper
                    stateProps={
                      new ControlStateProps(
                        "signed",
                        offerLetter,
                        null,
                        "wrapper for date"
                      )
                    }
                    render={() => {
                      return !offerLetter.signed ? (
                        ""
                      ) : (
                        <span
                          style={{
                            position: "absolute",
                            left: 290,
                            bottom: 90,
                          }}
                        >
                          {moment().format("MM/DD/YYYY")}
                        </span>
                      );
                    }}
                  />
                </div>
              </Document>
            )}
          </div>
        </div>
      </Modal.Body>
      <DocumentWizard
        document={offerLetter}
        fieldRefs={fieldRefs}
        refCount={1}
        zoom={zoom}
        setZoom={setZoom}
        onSubmit={handleSubmit}
      />
    </>
  );
}
