/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, { useEffect, useReducer } from 'react';
import { Button } from 'react-bootstrap';

export default function DocumentSignatureButton({stateProps, signature, x, y, innerRef, width = 118, height = 40, style}) {
    const [_, forceUpdate] = useReducer(x => x + 1, 0);

    useEffect(() => {
        stateProps.subscribe((_) => {
            forceUpdate();
        });
    }, [stateProps]);

    return ( stateProps.getValue() ? 
        <img ref={innerRef} style={style ? style : {position: 'absolute', top: y, left: x, width: width, height: height}} src={signature.image}/>
        :
        <Button ref={innerRef} style={{position: 'absolute', top: y, left: x, whiteSpace: 'nowrap', ...style, width: undefined, height: undefined}} onClick={() => {stateProps.stateObject.setState(stateProps.stateKey, true)}} variant='outline-primary'>
            Click To Sign Document
        </Button>
    )
}