/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import Address from "../models/Address";
import EmergencyContact from "../models/TCEmergencyContacts";
import { getResponse, getToken } from "./serviceTools";

export async function getEmployeeOnboardingData(onboardingToken: string) {
    const data = {
        type: 'employeeOnboarding',
        target: 'getOnboardingData',
        onboardingToken: onboardingToken
    }
    return await getResponse(data);
}

export async function submitDocument(document: object, onboardingToken: string) {
    const data = {
        type: 'employeeOnboarding',
        target: 'submitDocument',
        document,
        onboardingToken
    }
    return await getResponse(data);
}

export async function submitEContacts(onboardingToken: string, eContactData: EmergencyContact){
    const data = {
        type: 'employeeOnboarding',
        target: 'submitEContacts',
        onboardingToken,
        eContactData
    }

    return await getResponse(data);
}

export async function acknowledgeCompanyDocument(onboardingToken: string, uid: number) {
    const data = {
        type: 'employeeOnboarding',
        target: 'acknowledgeCompanyDocument',
        onboardingToken: onboardingToken,
        uid: uid
    }

    return await getResponse(data);
}

export async function updateTcUserDataEmployeeOnboarding(onboardingToken: string, ssn: string, dateOfBirth: string, address: Address, emergencyContacts: object, dl: string, ssnCard: string) {
    const data = {
        type: 'employeeOnboarding',
        target: 'updateTcUserData',
        onboardingToken,
        ssn,
        dateOfBirth,
        address,
        emergencyContacts,
        dl,
        ssnCard,
    }

    return await getResponse(data);
}

export async function updateLhUserDataEmployeeOnboarding(onboardingToken: string, ssn: string, dateOfBirth: string, address: Address, emergencyContacts: object[], dl: string, ssnCard: string) {
    const data = {
        type: 'employeeOnboarding',
        target: 'updateLhUserData',
        onboardingToken,
        ssn,
        dateOfBirth,
        address,
        emergencyContacts,
        dl,
        ssnCard,
    }

    return await getResponse(data);
}

export async function updateMTBUserDataEmployeeOnboarding(onboardingToken: string, ssn: string, dateOfBirth: string, address: Address, emergencyContacts: object[], dl: string, ssnCard: string) {
    const data = {
        type: 'employeeOnboarding',
        target: 'updateMtbUserData',
        onboardingToken,
        ssn,
        dateOfBirth,
        address,
        emergencyContacts,
        dl,
        ssnCard,
    }

    return await getResponse(data);
}

export async function downloadEdCustomOnboardingDocument(uid: number) {
    const data = {
        type: 'employeeOnboarding',
        target: 'downloadEdCustomOnboardingDocument',
        uid
    }
    return await getResponse(data);
}

export async function downloadMtbCustomOnboardingDocument(uid: number) {
    const data = {
        type: 'employeeOnboarding',
        target: 'downloadMtbCustomOnboardingDocument',
        uid
    }
    return await getResponse(data);
}